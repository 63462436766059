import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { getIntParam, movetotop } from 'src/app/Shared/commonMethods';
import { Globle } from 'src/app/Shared/global';
import { InvoiceModel } from 'src/Models/invoiceModel';
import { ProviderModel } from 'src/Models/providerModel';
import { BillingService } from "src/Services/billing.services";
@Component({
  selector: 'app-manage-invoice',
  templateUrl: './manage-invoice.component.html',
  styleUrls: ['./manage-invoice.component.css']
})
export class ManageInvoiceComponent implements OnInit {
  invoiceForm: FormGroup;
  isloading = false;
  invoiceModel = new InvoiceModel();

  listOfProvider: ProviderModel[];

  dropdownProviderList = [];
  selectedProviderItems = [];
  dropdownProviderSettings = {};
  providerList: any[] = [];
  invoiceDateString: string = '';

  editId: number = 0;
  invoiceDateValue: string = '';

  constructor(
    private billingService: BillingService,
    private _fb: FormBuilder,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private globle: Globle) {
    this.editId = getIntParam(this.activatedRoute.params, 'id');
  }

  ngOnInit() {
    this.invoiceForm = this._fb.group({
      id: [0],
      providerId: [0],
      invoiceNo: [''],
      invoiceDate: [''],
      billingStatus: [0],
      invoiceAmount: [''],
      notes: ['']
    });

    if (this.editId > 0) {
      this.titleService.setTitle('Invoice - Edit');
      this.GetInvoiceFindById();
    }
    else {
      this.titleService.setTitle('Invoice - Add');
      this.GetProvider();
    }

  }

  GetInvoiceFindById() {
    this.billingService.GetInvoiceFindById(this.editId).subscribe(
      data => {
        this.invoiceModel = data;
        if (this.invoiceModel != null) {
          this.invoiceForm.setValue({
            id: this.invoiceModel.id,
            providerId: this.invoiceModel.providerId,
            invoiceNo: this.invoiceModel.invoiceNo,
            invoiceDate: new Date(this.invoiceModel.invoiceDate),
            billingStatus: this.invoiceModel.billingStatus,
            invoiceAmount: this.invoiceModel.invoiceAmount,
            notes: this.invoiceModel.notes,
          });

          //this.setDateFormat(new Date(this.invoiceModel.invoiceDate));
          this.GetProvider();
        }

      },
      error => {
        console.log(error);
      }
    );

  }

  GetProvider() {
    this.billingService.GetProvidersForBilling().subscribe(
      data => {
        this.listOfProvider = data;
        this.dropdownProviderList = [];
        if (this.listOfProvider != null) {
          if (this.listOfProvider.length > 0) {
            for (let iCounter = 0; iCounter < this.listOfProvider.length; iCounter++) {
              this.dropdownProviderList.push(
                { "id": this.listOfProvider[iCounter].id, "itemName": this.listOfProvider[iCounter].name }
              );
            }
          }
        }


        this.selectedProviderItems = [];
        if (this.listOfProvider != null) {
          if (this.listOfProvider.length > 0) {
            if (this.invoiceModel != null && typeof (this.invoiceModel) != undefined) {
              let row = this.listOfProvider.filter(m => m.id == this.invoiceModel.providerId);

              if (row != null) {
                if (row.length > 0) {
                  this.selectedProviderItems.push(
                    { "id": row[0].id, "itemName": row[0].name }
                  );
                  this.providerList.push(row[0].id);
                }
              }
            }
          }
        }

        this.dropdownProviderSettings = {
          singleSelection: true,
          text: "Select Provider",
          selectAllText: 'Select All',
          unSelectAllText: 'Clear All',
          enableSearchFilter: true,
          // enableCheckAll: false,
          badgeShowLimit: 1,
          noDataLabel: 'No provider available',
          showCheckbox: false,
        };

      },
      error => {
        this.toastr.error(error, 'Error');
      }
    );
  }

  saveInvoice(invoiceForm: any) {
    this.invoiceModel = invoiceForm.value;

    if (this.providerList.length == 0) {
      this.toastr.error('Please select provider.', 'Error');
      return;
    }

    if (this.invoiceModel.invoiceNo == '') {
      this.toastr.error('Please enter invoice number.', 'Error');
      return;
    }

    if (this.invoiceDateString == '' || typeof (this.invoiceDateString) == 'undefined') {
      this.toastr.error('Please select invoice date.', 'Error');
      return;
    }

    if (this.invoiceModel.billingStatus == '') {
      this.toastr.error('Please select billing status.', 'Error');
      return;
    }

    if (this.invoiceModel.invoiceAmount == '') {
      this.toastr.error('Please enter invoice amount.', 'Error');
      return;
    }

    this.invoiceModel.createdBy = this.globle.cUser.id;
    this.invoiceModel.providerId = this.providerList.join(',');
    this.invoiceModel.invoiceDateString = this.invoiceDateString;
    this.billingService.saveInvoice(this.invoiceModel).subscribe(
      data => {
        if (data > 0) {
          if (this.editId > 0)
            this.toastr.success('Record updated successfully.', 'Success');
          else
            this.toastr.success('Record saved successfully.', 'Success');
          this.redirectToList();
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  redirectToList() {
    this.router.navigate(['/admin/invoice']);
  }

  removeElemetninArray(array, element) {
    return array.filter(e => e != element);
  }

  onItemSelect(item: any) {
    if (this.providerList != undefined) {
      this.providerList = this.removeElemetninArray(this.providerList, item['id']);
    }
    this.providerList.push(item['id']);
  }
  OnItemDeSelect(item: any) {
    if (this.providerList != undefined) {
      this.providerList = this.removeElemetninArray(this.providerList, item['id']);
    }
  }
  onSelectAll(items: any) {
    this.providerList = [];
    for (let iCounter = 0; iCounter < items.length; iCounter++) {
      this.providerList.push(items[iCounter]['id']);
    }
  }
  onDeSelectAll(items: any) {
    this.providerList = [];
  }

  setDateFormat($event) {
    let array = $event;
    if (array != null) {
      let dd = array.getDate().toString();
      if (parseInt(dd.toString()) < 10)
        dd = '0' + dd.toString();

      let mm = (array.getMonth() + 1).toString();
      if (parseInt(mm.toString()) < 10)
        mm = '0' + mm.toString();

      let sDate = array.getFullYear() + mm + dd;

      this.invoiceDateString = sDate;
    }
  }

}
