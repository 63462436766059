import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Globle } from 'src/app/Shared/global';
import { AuthService } from 'src/app/Shared/auth.service';
import { getIntParam, copyToClipboard } from 'src/app/Shared/commonMethods';
import { EmailTemplateService } from 'src/Services/email-template.service';

@Component({
  selector: 'app-editemailtemplate',
  templateUrl: './editemailtemplate.component.html',
  styleUrls: ['./editemailtemplate.component.css']
})
export class EditemailtemplateComponent implements OnInit {
  providerId = 0;
  id = 0;
  emailTemplateForm: FormGroup;
  isloadingstates: boolean;
  previewSubject: string;
  previewBody: string;
  customHtml: string;
  isRecertify=false;
  constructor
    (
      private _fb: FormBuilder,
      private toastr: ToastrService,
      private emailTemplatService: EmailTemplateService,
      private router: Router,
      private globle: Globle,
      private auth: AuthService,
      private activatedRoute: ActivatedRoute,
  ) {
    this.emailTemplateForm = this._fb.group({
      id: [this.id],
      subject: [''],
      body: [''],
      providerId: [this.providerId]
    });
  }

  ngOnInit() {
    this.id = getIntParam(this.activatedRoute.params, 'id');
    this.providerId = this.globle.cUser.providerId;
    this.editEmailTemplate();
  }

  editEmailTemplate() {
    this.isloadingstates = true;
    this.emailTemplatService.editEmailTemplate(this.id).subscribe(
      data => {        
        this.emailTemplateForm.patchValue(data);
        if(data && data.templateName.includes('Re-Certify')){this.isRecertify=true};        
        this.isloadingstates = false;
      }
    );
  }

  savemailTemplate(emailTemplate: any) {
    if (this.auth.checkValidation(emailTemplate)) {
      this.emailTemplatService.updateemailTemplate(this.emailTemplateForm.value).subscribe(
        data => {
          const msg = data.Id === 0 ? 'added' : 'updated';
          this.toastr.success('Email Template ' + msg + ' successfully', 'Success');
          this.router.navigate(['/admin/emailTemplates']);
        });
    }
  }
  showTemplateModal() {
    this.previewSubject = this.emailTemplateForm.get('subject').value;
    this.previewBody = this.emailTemplateForm.get('body').value;

  }
  cancelemailTemplate() {
    this.router.navigate(['/admin/emailTemplates']);
  }
}
