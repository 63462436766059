import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Globle } from 'src/app/Shared/global';
import { ApplicantService } from 'src/Services/applicant.service';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-applicant-import',
  templateUrl: './applicant-import.component.html',
  styleUrls: ['./applicant-import.component.css']
})
export class ApplicantImportComponent implements OnInit {
  file: File;
  isShowprogressSpinner: boolean;
  blockedDocument: boolean = false;

  importForm: FormGroup;

  constructor(private globle: Globle, private applicantService: ApplicantService, private toastr: ToastrService,
    private titleService: Title, private router: Router, private fb: FormBuilder,) {
    this.titleService.setTitle("Applicant Import");
  }

  ngOnInit() {
    this.importForm = this.fb.group({
      isEnrolledStatus: ['Success'],
      errorMessage: [''],
    });
  }

  onFileSelect(file) {
    this.file = file.target.files[0];
  }
  onFileUpload(importForm: any) {
    if (importForm.controls.isEnrolledStatus.value != 'Success') {
      if (importForm.controls.errorMessage.value == '' || importForm.controls.errorMessage.value == null || typeof (importForm.controls.errorMessage.value) == 'undefined') {
        this.toastr.error('Please enter error message', 'Error');
        return;
      }
    }

    this.blockDocument();
    let formData = new FormData();
    if (this.file) {
      formData.append("file", this.file);
      formData.append("isEnrolledStatus", importForm.controls.isEnrolledStatus.value);
      formData.append("UserId", this.globle.cUser.id.toString());
      formData.append("errorMessage", importForm.controls.errorMessage.value);
    }

    this.applicantService.UploadExcelFile(formData).subscribe(res => {
      this.file = undefined;
      if (res) {
        if (res.status == "Success") {
          document.getElementById("uploadFile").textContent = "";
          if (res.responseNewFilename) {
            this.applicantService.DownloadDocument(this.globle.cUser.authToken, { path: res.responseNewFilename }).subscribe(
              fileData => {
                this.unblockDocument();
                if (fileData != null) {
                  FileSaver.saveAs(fileData, res.responseNewFilename);
                  this.toastr.success(res.message, res.status);
                  this.router.navigate(['admin/applicants']);
                }
              }, error => {
                this.unblockDocument();
              });
          }
          else {
            this.unblockDocument();
            this.toastr.success(res.message, res.status);
            this.router.navigate(['admin/online/register/user']);
          }

        }
        else {
          this.toastr.error(res.message, res.status);
          this.unblockDocument();
        }
      }
      else
        this.unblockDocument();
    }, error => {
      this.unblockDocument();
    })

  }

  blockDocument() {
    this.isShowprogressSpinner = true;
    this.blockedDocument = true;
  }
  unblockDocument() {
    this.isShowprogressSpinner = false;
    this.blockedDocument = false;
  }

  rdbChange(event) {
    console.log(event);
    if (event == 'Failed')
      this.importForm.get('errorMessage').setValue('Validation Error - failed batch upload');
    else if (event == 'NotSubmitted')
      this.importForm.get('errorMessage').setValue('Not submitted');
    else
      this.importForm.get('errorMessage').setValue('');
  }

}
