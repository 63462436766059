import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationStatusModel } from 'src/Models/ApplicationStatusModel';
import { ToastrService } from 'ngx-toastr';
import { SubscriberMasterModel } from 'src/Models/SubscriberMaster.model';
import { RecertifyService } from 'src/Services/recertify.service';
import { RecertifyModel } from 'src/Models/RecertifyModel';

@Component({
  selector: 'app-view-recertify-data',
  templateUrl: './view-recertify-data.component.html',
  styleUrls: ['./view-recertify-data.component.css']
})
export class ViewRecertifyDataComponent implements OnInit {
  applicationStatusModel = new ApplicationStatusModel();
  subscriberModel = new SubscriberMasterModel()
  classEnrollJSON: any;
  recertifyId: number = 0;
  providerId: number = 0;
  isloading = false;

  isDOBShow: boolean = false;
  changeDateValues: any = null;
  recertifyDate: any = null;
  isRecertify: boolean = false;

  recertifyModel = new RecertifyModel();

  constructor(private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private recertifyService: RecertifyService,
    private titleService: Title,
    private globle: Globle,
  ) {
    if (sessionStorage.getItem('recertifyId') != null)
      this.recertifyId = +sessionStorage.getItem('recertifyId');
    this.providerId = this.globle.cUser.providerId;
    this.titleService.setTitle('View Recertify Data');

    if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5)
      this.isDOBShow = false;
    else
      this.isDOBShow = true;
  }

  ngOnInit() {
    this.GetSubscriberDetails();
  }

  // ngAfterViewChecked() {
  //   this.changeDateValues = null;
  // }

  GetSubscriberDetails() {
    this.isloading = true;
    this.recertifyService.GetSubscriberDetails(this.recertifyId).subscribe(
      data => {
        this.isloading = false;
        this.applicationStatusModel = data;
        if (this.applicationStatusModel != null) {
          
          this.isRecertify = this.applicationStatusModel.isRecertify;
          this.recertifyDate = this.applicationStatusModel.recertificationDeadlineDate;
          this.changeDateValues = this.applicationStatusModel.recertificationDeadlineDate;
        }
        if (data.classEnrollJSON != null) {
          let obj = JSON.parse(data.classEnrollJSON);
          this.subscriberModel = obj.subscriberMasterModel;
        }

      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  returnToList() {
    if (sessionStorage.getItem('recertifyId') != null)
      sessionStorage.removeItem('recertifyId')
    this.router.navigate(['/admin/applicants'])
  }

  saveData() {
    if (this.isRecertify) {
      if (this.recertifyDate == '' || this.recertifyDate == null || this.recertifyDate == 'undefined') {
        this.toastr.error('Please Select Recertify Date.', 'Error');
        return;
      }
    }
    this.recertifyModel.isRecertify = this.isRecertify;
    this.recertifyModel.recertificationDeadlineDate = this.recertifyDate;
    this.recertifyModel.recertifyId = this.recertifyId;
    this.recertifyService.SaveRecertify(this.recertifyModel).subscribe(
      data => {        
        if (data > 0) {
          this.returnToList();
          this.toastr.success('Record updated for recertify.', 'Success');
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  changeDate(value: Date) {
    this.changeDateValues = value;
    
    let array = value;
    if (array != null) {
      let dd = array.getDate().toString();
      if (parseInt(dd.toString()) < 10)
        dd = '0' + dd.toString();

      let mm = (array.getMonth() + 1).toString();
      if (parseInt(mm.toString()) < 10)
        mm = '0' + mm.toString();

      let sDate = array.getFullYear() + mm + dd;

      this.recertifyDate = sDate;
    }
  }

  clearDate() {
    this.changeDateValues = '';
    this.recertifyDate = '';
  }

  setIsRecertify($event) {
    this.isRecertify = $event.currentTarget.checked;
    if (!this.isRecertify) {
      this.changeDateValues = '';
      this.recertifyDate = '';
    }
  }
}
