import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { BillingFilterModel } from 'src/Models/billingFilterModel';
import { ApplicantsModel } from 'src/Models/applicantsModel';
import { UsersService } from 'src/Services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationStatusModel } from 'src/Models/ApplicationStatusModel';
import { getIntParam, copyToClipboard } from 'src/app/Shared/commonMethods';
import { UserModel } from 'src/Models/usersModel';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ApplicationUpdate } from 'src/Models/ApplicationUpdate';
import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/Services/state.service';
import { LocationService } from 'src/Services/location.service';
import { ProviderZone } from 'src/Services/providerzone.services';
import { ProviderZoneModel } from 'src/Models/providerZoneModel';
import { Validators } from '@angular/forms';
@Component({
    selector: 'app-manage-transfer-application',
    templateUrl: './manage-transfer-application.component.html',
    styleUrls: ['./manage-transfer-application.component.css']
})
export class ManageTransferApplicationComponent implements OnInit {
    applicationStatusModel = new ApplicationStatusModel();
    id: number = 0;
    appId: number = 0;
    providerId = 0;
    applicationForm: FormGroup;
    userId: number = 0;
    isloading = false;
    statelist = [];
    stateShortNameTempValue: string = '';
    stateShortNameValue: string = '';
    changeDateValues: any;
    strCancelledDate: string = '';
    transferchangeDateValues: any;
    strtransferoutDate: string = '';
    // Localtion
    dropdownSettings = {};
    selectedLocation = [];
    locationText = '';
    locations = [];
    locationList: any[] = [];
    // Zone
    listOfZone: ProviderZoneModel[];
    dropdownZoneList = [];
    selectedZoneItems = [];
    dropdownZoneSettings = {};
    zoneList: any[] = [];
    isLocaitonDropDownVisible: boolean = false;
    isZoneDropDownVisible: boolean = false;
    locationCode: string = '';
    isFreshUser: boolean = false;
    isTribalLands: boolean = false;
    error_Description: string = '';
    error_Type: string = '';
    ssnValue: string = '';
    tinValue: string = '';
    isSubmittedPersonalDetails: boolean = false;
    isDOBShow: boolean = false;
    
    constructor(private stateService: StateService, private toastr: ToastrService, private _fb: FormBuilder,
        private activatedRoute: ActivatedRoute, private router: Router, private userService: UsersService,
        private titleService: Title, private globle: Globle,
        private providerZone: ProviderZone,
        private locationService: LocationService) {
        this.id = getIntParam(this.activatedRoute.params, 'id');
        this.appId = getIntParam(this.activatedRoute.params, 'id2');
        this.providerId = this.globle.cUser.providerId;
        this.applicationForm = this._fb.group({
            applicationHistoryId: [this.appId],
            id: [this.id],
            providerId: [this.providerId],
            isCancelled: [true],
            cancelledNotes: [''],
            streetAddress: [''],
            stateId: [''],
            city: [''],
            zipcode: [''],
            streetNumberTemp: [''],
            cityTemp: [''],
            stateIdTemp: [''],
            zipcodeTemp: [''],
            addressType: ['Mail'],
            stateShortName: [''],
            stateShortNameTemp: [''],
            phoneNumber: [''],
            cancelledDate: [''],
            locationId: [],
            zone: [],
            accountNumber: [''],
            isTribalLands: [this.isTribalLands],
            sSN: [''],
            tIN: [''],
            isTransferOut: [false],
            transferOutNotes: [''],
            transferOutDate: [''],
        });
        this.titleService.setTitle('Manage Applicant');
        this.userId = this.globle.cUser.id;
        this.applicationForm.get('tIN').setValidators([]);
        this.applicationForm.get('tIN').updateValueAndValidity();
        this.applicationForm.get('tIN').setValue('');
        if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5)
            this.isDOBShow = false;
        else
            this.isDOBShow = true;
    }

    ngOnInit() {
        this.SubscriberStausFindById();
        this.getState();
        this.getProviderLocation();
    }

    getState() {
        this.isloading = true;
        this.stateService.GetStateListEBB().subscribe(data => {
            if (data) {
                this.isloading = false;
                this.statelist = data;
            }
        }, error => {
            this.isloading = false;
        });
    }
    setShortName($event: any, isMainStateShortName: any) {
        this.getStateName($event.currentTarget.value, isMainStateShortName)
    }
    getStateName(id, isMainStateShortName) {
        if (id) {
            var state = this.statelist.filter((x: any) => x.stateCode == id)[0];
            if (state) {
                if (isMainStateShortName)
                    this.stateShortNameValue = state.stateCode;
                else {
                    this.stateShortNameTempValue = state.stateCode;
                }
                return state.name;
            }
            return '';
        }
    }
    SubscriberStausFindById() {
        this.userService.TransferSubscriberStausFindById(this.id).subscribe(
            data => {
                this.applicationStatusModel = data;
                if (this.ssnValue != null) {
                    this.ssnValue = this.applicationStatusModel.ssn;
                    this.tinValue = '';
                }
                else {
                    this.ssnValue = '';
                    this.tinValue = this.applicationStatusModel.tribalId;
                }
                if (this.applicationStatusModel != null) {
                    this.stateShortNameValue = this.applicationStatusModel.stateId;
                    this.stateShortNameTempValue = this.applicationStatusModel.stateIdTemp;
                }
                this.applicationForm.setValue({
                    applicationHistoryId: this.appId,
                    id: this.id,
                    providerId: this.providerId,
                    isCancelled: this.applicationStatusModel.isCancelled,
                    cancelledNotes: this.applicationStatusModel.cancelledNotes,
                    streetAddress: this.applicationStatusModel.streetAddress,
                    stateId: this.applicationStatusModel.stateId,
                    city: this.applicationStatusModel.city,
                    zipcode: this.applicationStatusModel.zipcode,
                    streetNumberTemp: this.applicationStatusModel.streetNumberTemp,
                    cityTemp: this.applicationStatusModel.cityTemp,
                    stateIdTemp: this.applicationStatusModel.stateIdTemp,
                    zipcodeTemp: this.applicationStatusModel.zipcodeTemp,
                    stateShortName: this.applicationStatusModel.stateShortName,
                    stateShortNameTemp: this.applicationStatusModel.stateShortNameTemp,
                    addressType: this.applicationStatusModel.streetAddress == this.applicationStatusModel.streetNumberTemp ? 'Mail' : 'Home',
                    phoneNumber: this.applicationStatusModel.phoneNumber,
                    cancelledDate: this.applicationStatusModel.cancelledDate,
                    isTransferOut: this.applicationStatusModel.isTransferOut,
                    transferOutNotes: this.applicationStatusModel.transferOutNotes,
                    transferOutDate: this.applicationStatusModel.transferOutDate,
                    locationId: [],
                    zone: [],
                    accountNumber: this.applicationStatusModel.locationCode + this.applicationStatusModel.customerId,
                    isTribalLands: this.applicationStatusModel.ebbpTribalBenefitFlag == "1" ? true : false,
                    sSN: this.ssnValue,
                    tIN: this.tinValue,
                });
                this.locationCode = this.applicationStatusModel.locationCode;
                this.isTribalLands = this.applicationStatusModel.ebbpTribalBenefitFlag == "1" ? true : false
                this.selectedLocation = [];
                this.locationList = [];
                this.selectedLocation.push({ 'id': this.applicationStatusModel.locationId, 'name': this.applicationStatusModel.locationName });
                this.locationList.push(this.applicationStatusModel.locationId);
                this.selectedZoneItems = [];
                this.zoneList = [];
                this.selectedZoneItems.push({ 'id': this.applicationStatusModel.zoneId, 'itemName': this.applicationStatusModel.zoneName });
                this.zoneList.push(this.applicationStatusModel.zoneId);
                if (this.applicationStatusModel.locationCode != '000000')
                    this.isFreshUser = false;
                else
                    this.isFreshUser = true;
                this.getProviderZoneDropDown(this.applicationStatusModel.locationId);
                if (this.applicationStatusModel.locationId > 0)
                    this.isLocaitonDropDownVisible = true;
                else
                    this.isLocaitonDropDownVisible = false;
                if (this.applicationStatusModel.zoneId > 0)
                    this.isZoneDropDownVisible = true;
                else
                    this.isZoneDropDownVisible = false;
                this.dropdownZoneSettings = {
                    singleSelection: true,
                    text: "Select Zone",
                    selectAllText: 'Select All',
                    unSelectAllText: 'Clear All',
                    enableSearchFilter: true,
                    enableCheckAll: true,
                    badgeShowLimit: 1,
                    showCheckbox: false,
                    noDataLabel: 'No Zone available',
                    disabled: this.isZoneDropDownVisible,
                };
                this.dropdownSettings = {
                    singleSelection: true,
                    labelKey: "name",
                    primaryKey: "id",
                    text: "Select location",
                    enableSearchFilter: true,
                    classes: "dropdownborders",
                    noDataLabel: 'No location available',
                    showCheckbox: false,
                    enableFilterSelectAll: false,
                    disabled: this.isLocaitonDropDownVisible,
                    escapeToClose: true
                };
                this.error_Description = data.error_Description;
                this.error_Type = data.error_Type;
            },
            error => {
                console.log(error);
            }
        );
    }
    returnToList() {
        this.router.navigate(['/admin/applicants']);
    }
    transferApplication(applicationForm) {
        this.isSubmittedPersonalDetails = true;
        if (this.applicationForm.invalid) {
            return;
        }
        this.error_Description = '';
        this.error_Type = '';
        this.applicationStatusModel = applicationForm.value;
        this.applicationStatusModel.modifiedById = this.globle.cUser.id;
        // if (applicationForm.value.accountNumber == null || applicationForm.value.accountNumber == '' || typeof (applicationForm.value.accountNumber) == 'undefined') {
        //     this.toastr.error('Please enter account number', 'Error');
        //     return;
        // }
        // if (applicationForm.value.accountNumber != null && applicationForm.value.accountNumber != '' && typeof (applicationForm.value.accountNumber) != 'undefined') {
        //     if (applicationForm.value.accountNumber.length < 12) {
        //         this.toastr.error('Please valid account number', 'Error');
        //         return;
        //     }
        // }
        if (this.applicationStatusModel.phoneNumber == '' || this.applicationStatusModel.phoneNumber == null) {
            this.toastr.error('Please enter phone number', 'Error');
            return;
        }
        if (this.applicationStatusModel.streetAddress == '' || this.applicationStatusModel.streetAddress == null) {
            this.toastr.error('Please enter street address', 'Error');
            return;
        }
        if (this.applicationStatusModel.stateId == '' || this.applicationStatusModel.stateId == null) {
            this.toastr.error('Please select state', 'Error');
            return;
        }
        if (this.applicationStatusModel.city == '' || this.applicationStatusModel.city == null) {
            this.toastr.error('Please enter city', 'Error');
            return;
        }
        if (this.applicationStatusModel.zipcode == '' || this.applicationStatusModel.zipcode == null) {
            this.toastr.error('Please enter zipcode', 'Error');
            return;
        }
        if (this.applicationStatusModel.zipcode != '' || this.applicationStatusModel.zipcode != null) {
            if (this.applicationStatusModel.zipcode.length <= 4) {
                this.toastr.error('Please enter zipcode minimum 5 digits', 'Error');
                return;
            }
        }
        if (applicationForm.value['addressType'] == 'Home') {
            if (this.applicationStatusModel.streetNumberTemp == '' || this.applicationStatusModel.streetNumberTemp == null) {
                this.toastr.error('Please enter mailing street address', 'Error');
                return;
            }
            if (this.applicationStatusModel.stateIdTemp == '' || this.applicationStatusModel.stateIdTemp == null) {
                this.toastr.error('Please select mailing state', 'Error');
                return;
            }
            if (this.applicationStatusModel.cityTemp == '' || this.applicationStatusModel.cityTemp == null) {
                this.toastr.error('Please enter mailing city', 'Error');
                return;
            }
            if (this.applicationStatusModel.zipcodeTemp == '' || this.applicationStatusModel.zipcodeTemp == null) {
                this.toastr.error('Please enter mailing zipcode', 'Error');
                return;
            }
            if (this.applicationStatusModel.zipcodeTemp != '' || this.applicationStatusModel.zipcodeTemp != null) {
                if (this.applicationStatusModel.zipcodeTemp.length <= 4) {
                    this.toastr.error('Please enter mailing zipcode minimum 5 digits', 'Error');
                    return;
                }
            }
        }
        if (this.applicationStatusModel.isCancelled) {
            if (this.changeDateValues == '' || this.changeDateValues == null) {
                this.toastr.error('Please select cancelled date', 'Error');
                return;
            }
            else {
                this.applicationStatusModel.cancelledDate = this.strCancelledDate;
            }
        }
        else {
            this.changeDateValues = '';
            this.applicationStatusModel.cancelledDate = null;
        }
        if (this.applicationStatusModel.isTransferOut) {
            if (this.transferchangeDateValues == '' || this.transferchangeDateValues == null) {
                this.toastr.error('Please select transferred out date', 'Error');
                return;
            }
            else {
                this.applicationStatusModel.transferOutDate = this.strtransferoutDate;
            }
        }
        else {
            this.transferchangeDateValues = '';
            this.applicationStatusModel.transferOutDate = null;
        }
        if (applicationForm.value['addressType'] != 'Home') {
            this.applicationStatusModel.cityTemp = this.applicationStatusModel.city;
            this.applicationStatusModel.stateIdTemp = this.applicationStatusModel.stateId;
            this.applicationStatusModel.stateShortNameTemp = this.applicationStatusModel.stateId;
            this.applicationStatusModel.streetNumberTemp = this.applicationStatusModel.streetAddress;
            this.applicationStatusModel.zipcodeTemp = this.applicationStatusModel.zipcode;
            this.applicationStatusModel.stateShortName = this.applicationStatusModel.stateId;
        }
        
        this.applicationStatusModel.isTribalLands = this.isTribalLands;
        this.applicationStatusModel.locationId = this.locationList.join(',');
        this.applicationStatusModel.zoneId = this.zoneList.join(',');
        this.applicationStatusModel.ssn = this.ssnValue;
        this.applicationStatusModel.tribalId = this.tinValue;
        this.isloading = true;
        this.userService.Applicationtransfer(this.applicationStatusModel).subscribe(
            data => {
                this.isloading = false;
                if (data['status'] == 'Scuccess') {
                    this.toastr.success('Application updated successfully', 'Success');
                    this.returnToList();
                }
                else if (data['result'] > 0) {
                    this.toastr.success('Application updated successfully', 'Success');
                    this.returnToList();
                }
                else {
                    this.toastr.error(data['error_Description'], data['error_Type']);
                    this.error_Description = data['error_Description'];
                    this.error_Type = data['error_Type'];
                    this.SubscriberStausFindById();
                }
            },
            error => {
                this.isloading = false;
                console.log(error);
            }
        );
    }
    freshUserUpdate(applicationForm) {
        this.applicationStatusModel = applicationForm.value;
        this.applicationStatusModel.modifiedById = this.globle.cUser.id;
        if (applicationForm.value.accountNumber == null || applicationForm.value.accountNumber == '' || typeof (applicationForm.value.accountNumber) == 'undefined') {
            this.toastr.error('Please enter account number', 'Error');
            return;
        }
        if (applicationForm.value.accountNumber != null && applicationForm.value.accountNumber != '' && typeof (applicationForm.value.accountNumber) != 'undefined') {
            if (applicationForm.value.accountNumber.length < 12) {
                this.toastr.error('Please valid account number', 'Error');
                return;
            }
        }
        if (this.locationList.length == 0) {
            this.toastr.error('Please select location', 'Error');
            return;
        }
        if (this.zoneList.length == 0) {
            this.toastr.error('Please select zone', 'Error');
            return;
        }
        if (applicationForm.value.accountNumber.indexOf('-') > 0) {
            let ary = applicationForm.value.accountNumber.toString().split('-');
            this.applicationStatusModel.customerId = ary[1];
            this.applicationStatusModel.locationCode = ary[0];
        }
        else {
            this.applicationStatusModel.locationCode = applicationForm.value.accountNumber.toString().substring(0, 6);
            this.applicationStatusModel.customerId = applicationForm.value.accountNumber.toString().substring(6, 13);
        }
        this.applicationStatusModel.locationId = this.locationList.join(',');
        this.applicationStatusModel.zoneId = this.zoneList.join(',');
        this.applicationStatusModel.isTribalLands = this.isTribalLands;
        this.isloading = true;
        this.userService.FreshUserUpdate(this.applicationStatusModel).subscribe(
            data => {
                this.isloading = false;
                if (data['status'] == 'Scuccess') {
                    this.toastr.success('Application updated successfully', 'Success');
                    this.returnToList();
                }
                else if (data['result'] > 0) {
                    this.toastr.success('Application updated successfully', 'Success');
                    this.returnToList();
                }
                else {
                    this.toastr.error(data['error_Description'], data['error_Type']);
                    this.returnToList();
                }
            },
            error => {
                this.isloading = false;
                console.log(error);
            }
        );
    }
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    changeDate(value: Date) {
        this.changeDateValues = value;
        let array = value;
        if (array != null) {
            let dd = array.getDate().toString();
            if (parseInt(dd.toString()) < 10)
                dd = '0' + dd.toString();
            let mm = (array.getMonth() + 1).toString();
            if (parseInt(mm.toString()) < 10)
                mm = '0' + mm.toString();
            let sDate = array.getFullYear() + mm + dd;
            this.strCancelledDate = sDate;
        }
    }
    clearDate() {
        this.changeDateValues = '';
    }
    getProviderLocation(): void {
        this.isloading = true;
        this.locationService.getProviderLocationDropdown(this.providerId).subscribe(
            data => {
                if (data != null) {
                    for (let iCounter = 0; iCounter < data.length; iCounter++) {
                        this.locations.push({ 'id': data[iCounter].id, 'name': data[iCounter].name })
                    }
                }
            }
        );
    }
    // Location
    onLocationSelect(item) {
        this.locationList = [];
        this.zoneList = []
        this.selectedZoneItems = []
        if (this.locationList != undefined) {
            this.locationList = this.removeElemetninArray(this.locationList, item['id']);
        }
        this.locationList.push(item['id']);
        this.getProviderZoneDropDown(this.locationList.join(','));
    }
    onLocationDeSelect(item) {
        this.locationList = [];
        this.zoneList = []
        this.selectedZoneItems = []
        if (this.locationList != undefined) {
            this.locationList = this.removeElemetninArray(this.locationList, item['id']);
        }
        this.getProviderZoneDropDown(this.locationList.join(','));
    }
    onLocationSelectAll(items) {
        this.locationList = [];
        this.zoneList = []
        this.selectedZoneItems = []
        for (let iCounter = 0; iCounter < items.length; iCounter++) {
            this.locationList.push(items[iCounter]['id']);
        }
        this.getProviderZoneDropDown(this.locationList.join(','));
    }
    onLocationDeSelectALL(items) {
        this.locationList = [];
        this.zoneList = []
        this.selectedZoneItems = []
        this.getProviderZoneDropDown(this.locationList.join(','));
    }

    getProviderZoneDropDown(id): void {
        this.providerZone.getProviderZoneDropDownByLocationId(this.providerId, this.userId, id).subscribe(
            data => {
                this.listOfZone = data;
                this.dropdownZoneList = [];
                if (this.listOfZone != null) {
                    if (this.listOfZone.length > 0) {
                        for (let iCounter = 0; iCounter < this.listOfZone.length; iCounter++) {
                            this.dropdownZoneList.push(
                                { "id": this.listOfZone[iCounter].id, "itemName": this.listOfZone[iCounter].zoneCode }
                            );
                        }
                    }
                }
            }
        );
    }

    removeElemetninArray(array, element) {
        return array.filter(e => e != element);
    }

    // Zone 
    onZoneSelect(item) {
        this.zoneList = [];
        if (this.zoneList != undefined) {
            this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
        }
        this.zoneList.push(item['id']);
    }

    onZoneDeSelect(item) {
        this.zoneList = [];
        if (this.zoneList != undefined) {
            this.zoneList = this.removeElemetninArray(this.zoneList, item['id']);
        }
    }

    onZoneSelectAll(items) {
        this.zoneList = [];
        for (let iCounter = 0; iCounter < items.length; iCounter++) {
            this.zoneList.push(items[iCounter]['id']);
        }
    }

    onZoneDeSelectAll(item) {
        this.zoneList = [];
    }

    checkTribalLands($event) {
        this.isTribalLands = $event.currentTarget.checked;
    }

    onChangeSSN(type: string) {
        if (this.tinValue == '' && this.ssnValue == '') {
            this.applicationForm.get('tIN').enable();
            this.applicationForm.get('sSN').enable();
        }
        else if (this.ssnValue != '' && this.tinValue == '') {
            this.applicationForm.get('tIN').disable();
            this.applicationForm.get('sSN').enable();
        }
        else if (this.ssnValue == '' && this.tinValue != '') {
            this.applicationForm.get('tIN').enable();
            this.applicationForm.get('sSN').disable();
        }
        if (type == 'SSN' && this.tinValue == '') {
            this.applicationForm.get('sSN').setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
            this.applicationForm.get('tIN').setValidators([]);
            this.applicationForm.get('sSN').updateValueAndValidity();
            this.applicationForm.get('tIN').updateValueAndValidity();
            this.applicationForm.get('tIN').setValue('');
        }
        else if (this.ssnValue == '') {
            this.applicationForm.get('tIN').setValidators([Validators.required, Validators.minLength(2), Validators.maxLength(20)]);
            this.applicationForm.get('sSN').setValidators([]);
            this.applicationForm.get('tIN').updateValueAndValidity();
            this.applicationForm.get('sSN').updateValueAndValidity();
            this.applicationForm.get('sSN').setValue('');
        }
    }

    get fpi() { return this.applicationForm.controls; }

    transferchangeDate(value: Date) {
        this.transferchangeDateValues = value;
        let array = value;
        if (array != null) {
            let dd = array.getDate().toString();
            if (parseInt(dd.toString()) < 10)
                dd = '0' + dd.toString();
            let mm = (array.getMonth() + 1).toString();
            if (parseInt(mm.toString()) < 10)
                mm = '0' + mm.toString();
            let sDate = array.getFullYear() + mm + dd;
            this.strtransferoutDate = sDate;
        }
    }

    transferclearDate() {
        this.transferchangeDateValues = '';
    }
}