import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Globle } from 'src/app/Shared/global';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationStatusModel } from 'src/Models/ApplicationStatusModel';
import { ToastrService } from 'ngx-toastr';
import { ConsentService } from 'src/Services/Consent.service';
import { SubscriberMasterModel } from 'src/Models/SubscriberMaster.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-view-consent',
  templateUrl: './view-consent.component.html',
  styleUrls: ['./view-consent.component.css']
})
export class ViewConsentComponent implements OnInit {
  applicationStatusModel = new ApplicationStatusModel();
  subscriberModel = new SubscriberMasterModel()
  classEnrollJSON: any;
  appHistioryId: number = 0;
  providerId: number = 0;
  isloading = false;

  isDOBShow: boolean = false;
  consentHistoryId: number=0;
  applicationHistoryId: any;

  constructor(private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private consentService: ConsentService,
    private titleService: Title,
    private globle: Globle,
  ) {
    //if (sessionStorage.getItem('consentId') != null)
    //  this.appHistioryId = +sessionStorage.getItem('consentId');

    if (sessionStorage.getItem('consentHistoryId') != null)
    this.consentHistoryId = parseInt(sessionStorage.getItem('consentHistoryId'));
    if (sessionStorage.getItem('applicationHistoryId') != null)
    this.applicationHistoryId = parseInt(sessionStorage.getItem('applicationHistoryId'));

    this.providerId = this.globle.cUser.providerId;
    this.titleService.setTitle('View Consent');
    
    if (this.globle.cUser.userGroupId == 2 || this.globle.cUser.userGroupId == 5)
      this.isDOBShow = false;
    else
      this.isDOBShow = true;
  }

  ngOnInit() {
    this.GetSubscriberDetails();
  }

  GetSubscriberDetails() {
    this.isloading = true;
    this.consentService.GetSubscriberDetails(this.consentHistoryId,this.applicationHistoryId).subscribe(
      data => {
        this.isloading = false;
        this.applicationStatusModel = data;
        if (data.classEnrollJSON != null) {
          let obj = JSON.parse(data.classEnrollJSON);
          this.subscriberModel = obj.subscriberMasterModel;
        }

      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  returnToList() {
    if (sessionStorage.getItem('consentId') != null)
      sessionStorage.removeItem('consentId')
    this.router.navigate(['/admin/applicants'])
  }

  createPDF() {
    debugger;
    this.isloading = true;
    this.consentService.CreatePDF('', '', this.consentHistoryId,this.applicationHistoryId).subscribe(
      data => {
        debugger
        console.log(data);
        this.downloadFile('ConsentForm.pdf', data['url'], data['fileName']);
      },
      error => {
        this.isloading = false;
        console.log(error);
      }
    );
  }

  public downloadFile(filename: any, url: any, orignalFileName: any) {
    this.consentService.downloadPdf(url).subscribe(
      (res) => {
        FileSaver.saveAs(res, filename);
        this.consentService.DeletePDF(orignalFileName).subscribe(
          data => {
          },
        );
        this.isloading = false;
      }
    );
  }
}
