import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/Services/users.service';
import { UserType } from 'src/app/Shared/AppEnums';
import { Globle } from 'src/app/Shared/global';
import { movetotop } from 'src/app/Shared/commonMethods';
import { UserRightsService } from 'src/Services/userrights.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {
  dt = '';
  userType = '';
  providerId = 0;
  dynamicHeight: any;
  menuList: Array<any>;
  currentEnvironment: string = '';
  constructor(private router: Router, private userService: UsersService, private globle: Globle, private userRightsService: UserRightsService) {
    this.dynamicHeight = { 'min-height': (window.innerHeight - 101) + 'px' };
    if (this.globle.cUser.currentenvironment != 'Live') {
      this.currentEnvironment = this.globle.cUser.currentenvironment + ' Website' ;
    }

  }

  ngOnInit() {
    movetotop();
    this.dt = Date();
    this.userType = UserType[this.globle.cUser.userTypeId];
    this.providerId = this.globle.cUser.providerId;

    this.getMenuByGroupId(this.globle.cUser.userGroupId);

    // if (this.userRightsService.currentUserAccessValue) {
    //   this.menuList = this.userRightsService.currentUserAccessValue as Array<any>;
    // }

  }

  logout(): void {
    this.userService.logout();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    //this.router.navigate(['admin/login']);    
    let fullUrl = window.location.href.split('#');
    window.location.href = fullUrl[0] + '/#/admin';
  }

  getMenuByGroupId(userGroupId: number): void {
    this.userRightsService.getMenuByGroupId(userGroupId).subscribe(
      data => {
        this.menuList = data;
      });
  }

  // setOprationSession(logout: string) {
  //   sessionStorage.setItem('LoginStatus', logout);
  // }

  // SetTool(ToolType: string) {
  //   let AccessibleTools = sessionStorage.getItem('UserAccessibleTools');
  //   if (AccessibleTools.toLowerCase().indexOf(ToolType.toLowerCase()) !== -1) {
  //     this.SelectedTool = ToolType;
  //     sessionStorage.setItem('UserLoginTool', ToolType);
  //     this.router.navigate(['admin/' + this.SelectedTool + '/dashboard']);
  //   } else {
  //     sessionStorage.setItem('AccessDeniedTool', ToolType);
  //     this.router.navigate(['admin/' + this.SelectedTool + '/access-denied']);
  //   }
  // }

  getLink(url) {
    return `${url}/${this.globle.cUser.providerId}`;
  }
}
