import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HttpUrlEncodingCodec, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SortablejsModule } from 'angular-sortablejs';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { OrderModule } from 'ngx-order-pipe';
import { ToastrModule } from 'ngx-toastr';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BlockUIModule } from 'primeng/blockui';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DialogModule } from 'primeng/dialog';
// 3rd Party Component
import { EditorModule } from 'primeng/editor';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileUploadModule } from 'ng2-file-upload';
import { TreeModule } from 'primeng/tree';
import { TreeNode, ConfirmationService } from 'primeng/api';
// Services
// Admin Component
import { AdminLoginComponent } from './Admin/admin-login/admin-login.component';
import { DashboardComponent } from './Admin/dashboard/dashboard.component';
import { ProfileComponent } from './Admin/profile/profile.component';
import { AddEditProviderComponent } from './Admin/providers/add-edit-provider.component';
import { AddEditUserComponent } from './Admin/Users/add-edit-user.component';
import { UsersComponent } from './Admin/Users/users.component';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './Layout/admin-layout/admin-layout.component';
import { AuthInterceptor } from './Shared/auth.interceptor';
import { AuthService } from './Shared/auth.service';
import { AuthGuard } from './Shared/authGuard';
import { ConvertUTCToLocal } from './Shared/dateUTCToLocal';
import { Globle } from './Shared/global';
import { ProvidersComponent } from './Admin/providers/providers.component';
import { ForgotpasswordComponent } from './Admin/admin-login/forgotpassword.component';
import { EmailTemplateComponent } from './Admin/email-template/email-template.component';
import { EditemailtemplateComponent } from './Admin/email-template/editemailtemplate.component';
import { ReportdetailComponent } from './Admin/Report/reportdetail/reportdetail.component';
import { ZipcodeComponent } from './Admin/location/zipcode.component';
import { NewregistrationComponent } from './Admin/newregistration/newregistration.component';
import { ApplicationreviewComponent } from './Admin/applicationreview/applicationreview.component';
import { CompanyprofileComponent } from './Admin/companyprofile/companyprofile.component';
import { LocationComponent } from './Admin/location/location.component';
import { AddEditLocationComponent } from './Admin/location/add-edit-location.component';
import { UserrightsComponent } from './Admin/userrights/userrights.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EligibilityCheckStatusComponent } from './Admin/eligibility-check-status/eligibility-check-status.component';
import { AnalyticalReportComponent } from './Admin/Report/analytical-report/analytical-report.component';
import { ChartModule } from 'primeng/chart';
import { UnderMaintenanceComponent } from './Admin/under-maintenance/under-maintenance.component';
import { ManageMaintenanceComponent } from './Admin/under-maintenance/manage-maintenance.component';
// import { ProviderZoneComponent } from './Admin/provider-zone/provider-zone.component';
// import { AddEditProviderZoneComponent } from './Admin/provider-zone/add-edit-provider-zone.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { RateSetupComponent } from './Admin/rate-setup/rate-setup.component';
import { RateSetupHistoryComponent } from './Admin/rate-setup-history/rate-setup-history.component';
import { BillingExportComponent } from './Admin/billing-export/billing-export.component';
import { InvoiceListingComponent } from './Admin/Invoice/invoice-listing.component';
import { ManageInvoiceComponent } from './Admin/Invoice/manage-invoice.component';
import { SubscribersComponent } from './Admin/subscribers/subscribers.component';
import { ApplicantsComponent } from './Admin/applicants/applicants.component';
import { ManageSubscriberComponent } from './Admin/subscribers/manage-subscriber.component';
import { ManageApplicationComponent } from './Admin/applicants/manage-application.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AddNewUserComponent } from './Admin/applicants/add-new-user.component';
import { ViewRecertifyDataComponent } from './Admin/view-recertify-data/view-recertify-data.component';
import { SnapshotandSummaryReportComponent } from './Admin/applicants/snapshot-summary-report.component';
import { SnapshotandSummaryDetailsReportComponent } from './Admin/applicants/snapshot-summary-details-report.component';
import { NladVSInrollComponent } from './Admin/applicants/nlad-vs-inroll.component';
import { ViewApplicationComponent } from './Admin/applicants/view-application.component';
import { ApplicantImportComponent } from './Admin/applicant-import/applicant-import.component';
import { ManageTransferApplicationComponent } from './Admin/applicants/manage-transfer-application.component';
import { OnlineRegistrationListingComponent } from './Admin/online-registration-listing/online-registration-listing.component';
import { ViewConsentComponent } from './Admin/view-consent/view-consent.component';
import { ConsentHistoryComponent } from './Admin/applicants/consent-history.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLoginComponent,
    AdminLayoutComponent,
    DashboardComponent,
    ProfileComponent,
    ProvidersComponent,
    UsersComponent,
    AddEditUserComponent,
    ConvertUTCToLocal,
    AddEditProviderComponent,
    ForgotpasswordComponent,
    EmailTemplateComponent,
    EditemailtemplateComponent,
    ReportdetailComponent,
    NewregistrationComponent,
    ApplicationreviewComponent,
    CompanyprofileComponent,
    LocationComponent,
    AddEditLocationComponent,
    ZipcodeComponent,
    UserrightsComponent,
    EligibilityCheckStatusComponent,
    AnalyticalReportComponent,
    UnderMaintenanceComponent,
    ManageMaintenanceComponent,
    RateSetupComponent,
    RateSetupComponent,
    RateSetupHistoryComponent,
    BillingExportComponent,
    InvoiceListingComponent,
    ManageInvoiceComponent,
    SubscribersComponent,
    ApplicantsComponent,
    ManageSubscriberComponent,
    ManageApplicationComponent,
    AddNewUserComponent,
    ViewRecertifyDataComponent,
    SnapshotandSummaryReportComponent,
    SnapshotandSummaryDetailsReportComponent,
    NladVSInrollComponent,
    ViewApplicationComponent,
    ApplicantImportComponent,             
    ManageTransferApplicationComponent,
    OnlineRegistrationListingComponent,
    ViewConsentComponent,
    ConsentHistoryComponent,
    // ProviderZoneComponent,
    // AddEditProviderZoneComponent,
  ],
  imports: [
    Approutes,
    BrowserAnimationsModule,
    ProgressBarModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    BrowserModule,
    EditorModule,
    TreeModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    OrderModule,
    SortablejsModule.forRoot({ animation: 150 }),
    PaginationModule.forRoot(),
    FileUploadModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true
    }),
    ProgressSpinnerModule,
    BlockUIModule,
    PdfViewerModule,
    DialogModule,
    ConfirmDialogModule,
    ChartModule,
    AngularMultiSelectModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    Globle,
    AuthService,
    AuthGuard,
    DatePipe,
    HttpUrlEncodingCodec,
    ConfirmationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: Window, useValue: window },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
